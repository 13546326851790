import { useContext, useState, useEffect } from 'react';
import { EthereumContext } from '../eth/context';
import './Registrations.css';

const mapEvent = (event) => {
    console.log('event', event);
    return {
        blockNumber: event.blockNumber,
        who: event.args.who,
        tokenId: event.args.id,
        id: `${event.blockHash}/${event.transactionIndex}/${event.logIndex}`,
    };
};

function MintedNfts() {
    const { testNft } = useContext(EthereumContext);

    const [mintedNfts, setMintedNfts] = useState(undefined);

    useEffect(() => {
        console.log('testNft available filters', testNft.filters);
        const filter = testNft.filters.Minted();

        const listener = (...args) => {
            const event = args[args.length - 1];
            setMintedNfts((rs) => [mapEvent(event), ...(rs || [])]);
        };

        const subscribe = async () => {
            const past = await testNft.queryFilter(filter, 34848456, 'latest');
            setMintedNfts((past.reverse() || []).map(mapEvent));
            testNft.on(filter, listener);
        };

        subscribe();
        return () => testNft.off(filter, listener);
    }, [testNft]);

    return (
        <div className="Registrations">
            <h3>Last Mints 📝</h3>
            {mintedNfts === undefined && <span>Loading..</span>}
            {mintedNfts && (
                <ul>
                    {mintedNfts.map((r) => (
                        <li key={r.id}>
                            <span className="address">{r.who}</span> {'' + r.tokenId}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default MintedNfts;
