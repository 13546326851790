import { EthereumContext } from '../eth/context';
import { createProvider } from '../eth/provider';
import { createInstance } from '../eth/testNft';
import { createInstance as createTokenInstance } from '../eth/erc20Burnable';

import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MintNft from './MintNft';
import MintedNfts from './MintedNfts';

function App() {
    const provider = createProvider();
    const testNft = createInstance(provider);
    const paintToken = createTokenInstance(provider);
    const ethereumContext = { provider, testNft, paintToken };

    return (
        <div className="App">
            <header className="App-header">
                <h1>NFTs with some arbitrary data</h1>
                <p>powered by Defender Relayer meta-transactions</p>
            </header>
            <section className="App-content">
                <EthereumContext.Provider value={ethereumContext}>
                    <MintNft />
                    <MintedNfts />
                </EthereumContext.Provider>
            </section>
            <ToastContainer hideProgressBar={true} />
        </div>
    );
}

export default App;
