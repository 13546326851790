import { useRef, useState, useContext } from 'react';
import { mintNft } from '../eth/mint';
import { EthereumContext } from '../eth/context';
import { toast } from 'react-toastify';
import './MintNft.css';

function MintNft() {
    const nameInput = useRef(null);
    const paintInput = useRef(null);
    const [submitting, setSubmitting] = useState(false);
    const { testNft, provider, paintToken } = useContext(EthereumContext);

    const sendTx = async (event) => {
        event.preventDefault();
        const arbitraryData = nameInput.current.value;
        const paintBurnData = paintInput.current.value;
        setSubmitting(true);

        try {
            const response = await mintNft(testNft, paintToken, provider, arbitraryData, paintBurnData);
            const hash = response.hash;
            const onClick = hash ? () => window.open(`https://mumbai.polygonscan.com/tx/${hash}`) : undefined;
            toast('Transaction sent!', { type: 'info', onClick });
            nameInput.current.value = '';
        } catch (err) {
            console.error(err);
            toast(err.message || err, { type: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="Container">
            <form onSubmit={sendTx}>
                <input required={true} placeholder="Mint your nft with some data" ref={nameInput}></input>
                <input required={true} placeholder="Amount of Paint to burn" ref={paintInput}></input>
                <button type="submit" disabled={submitting}>
                    {submitting ? 'Minting...' : 'Mint'}
                </button>
            </form>
        </div>
    );
}

export default MintNft;
