/* eslint-disable no-unused-vars */
import { ethers } from 'ethers';

export const SupportedChain = {
    Ethereum: 1,
    Goerli: 5,
    Polygon: 137,
    Mumbai: 80001,
    LocalhostL1: 123456789,
    LocalhostL2: 987654321,
};

export const SUPPORTED_CHAINS = [
    SupportedChain.Ethereum,
    SupportedChain.Goerli,
    SupportedChain.Polygon,
    SupportedChain.Mumbai,
    SupportedChain.LocalhostL1,
    SupportedChain.LocalhostL2,
];

const endpoints = {
    [SupportedChain.Ethereum]: 'https://rpc.ankr.com/eth',
    [SupportedChain.Goerli]: 'https://rpc.ankr.com/eth_goerli',
    [SupportedChain.Polygon]: 'https://rpc.ankr.com/polygon',
    [SupportedChain.Mumbai]: 'https://rpc.ankr.com/polygon_mumbai',
    [SupportedChain.LocalhostL1]: 'http://localhost:8545',
    [SupportedChain.LocalhostL2]: 'http://localhost:8545',
};

export function createProvider(network = SupportedChain.Mumbai) {
    if (!SUPPORTED_CHAINS.includes(network)) {
        throw new Error(`Unsupported chain ${network}`);
    }

    return new ethers.providers.JsonRpcProvider(endpoints[network], network);
}
