import { ethers } from 'ethers';
import { createInstance } from './forwarder';
import { signMetaTxRequest } from './signer';
import { SupportedChain } from './provider';

async function sendTx(nftContract, arbitraryData, paintToBurn) {
    console.log(`Sending mint tx to mint nft with data=${arbitraryData}`);
    return nftContract.mint(arbitraryData, paintToBurn);
}

async function sendMetaTx(nftContract, provider, signer, arbitraryData, paintToBurn) {
    console.log(`Sending mint meta-tx to mint nft with data=${arbitraryData}, paintToBurn=${paintToBurn}`);
    const url = process.env.REACT_APP_WEBHOOK_URL;
    if (!url) throw new Error(`Missing relayer url`);

    const forwarder = createInstance(provider);
    const from = await signer.getAddress();
    const data = nftContract.interface.encodeFunctionData('mint', [arbitraryData, paintToBurn]);
    const to = nftContract.address;

    const request = await signMetaTxRequest(signer.provider, forwarder, { to, from, data });

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: { 'Content-Type': 'application/json' },
    });
}

export async function mintNft(nftContract, erc20Burnable, provider, arbitraryData, paintToBurn, metaTx = true) {
    if (!arbitraryData) throw new Error(`Name cannot be empty`);
    if (!window.ethereum) throw new Error(`User wallet not found`);
    if (!erc20Burnable) throw new Error(`Missing paint token contract`);
    if (!paintToBurn) throw new Error(`Missing paint to burn`);
    const paintToBurnNumber = ethers.utils.parseEther(paintToBurn);
    if (isNaN(paintToBurnNumber)) throw new Error(`Invalid paint to burn`);
    if (paintToBurnNumber <= 0) throw new Error(`Paint to burn must be positive`);

    await window.ethereum.enable();
    const userProvider = new ethers.providers.Web3Provider(window.ethereum);
    const userNetwork = await userProvider.getNetwork();
    if (userNetwork.chainId !== SupportedChain.Mumbai) throw new Error(`Please switch to Mumbai for signing`);

    const signer = userProvider.getSigner();
    const from = await signer.getAddress();

    // check paint balance
    const paintBalance = await erc20Burnable.balanceOf(from);
    if (paintBalance.lt(paintToBurnNumber)) throw new Error(`Insufficient paint balance`);

    // check allowance
    const allowance = await erc20Burnable.allowance(from, nftContract.address);
    if (allowance.lt(paintToBurnNumber)) {
        console.log(`Approving ${paintToBurnNumber} paint to be burned by nft contract ` + nftContract.address);
        const approveTx = await erc20Burnable.connect(signer).approve(nftContract.address, paintToBurnNumber);
        await approveTx.wait();
    }

    if (metaTx) {
        return sendMetaTx(nftContract, provider, signer, arbitraryData, paintToBurnNumber);
    }

    const balance = await provider.getBalance(from);
    const canSendTx = balance.gt(1e15);
    if (canSendTx) return sendTx(nftContract.connect(signer), arbitraryData, paintToBurnNumber);
    else return sendMetaTx(nftContract, provider, signer, arbitraryData, paintToBurnNumber);
}
